<template>
    <section id="header">
        <v-row no-gutters>
            <v-img  :src="require('../assets/photo-1487017159836-4e23ece2e4cf.jpg')" :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'" >
                <v-theme-provider dark>                    
                    <v-container fill-height>
                        <v-row align="start" class="white--text mt-4 ml-n6" justify="start">
                            <v-Card id="headcontain">    
                            <v-col class="white--text text-center" cols="12" tag="h1">
                                <span :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']" class="font-weight-black" >
                                    KREADORES
                                </span>
                                <br>
                                    <v-divider class="mx-auto"></v-divider>
                                    <v-divider class="mx-auto"></v-divider>
                                    <v-divider class="mx-auto"></v-divider>
                                    <v-divider class="mx-auto"></v-divider>
                                <span :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']" class="font-weight-light">
                                    BIENVENIDOS
                                </span>
                            </v-col>                                    
                            </v-Card>
                        </v-row>     
                        <v-container fill-height >
                        <v-row align="center" class="white--text mx-auto mt-n26" justify="center">     
                            <v-col class="white--text text-center" cols="12" tag="h1">                
                            <v-btn class="align-self-end" fab outlined @click="$vuetify.goTo('#about-me')" >
                                <v-icon>mdi-chevron-double-down</v-icon>
                            </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>                                     
                    </v-container>     
                </v-theme-provider>    
            </v-img>                    
        </v-row>                
    </section>
</template>
<script>
  
  export default {
    name: 'Home',
    absolute: false,
    overlay: true,
  }
</script>
<style>
    #headcontain{
        background-color: rgba(233, 17, 17, 0.3);
    }
    
</style>