<template>
    <div>
        <home></home>
        <about></about>
        <feats></feats>
        <contact></contact>        
    </div>
</template>

<script>
    import Home from './Home.vue'
    import About from './About.vue'
    import Feats from './Features.vue'
    import Contact from './Contact.vue'
    export default {
        components: {
            Home,
            About,
            Contact,
            Feats,
        },
    }
</script>